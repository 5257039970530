import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col py-0" }
const _hoisted_3 = { class: "subtitle-1" }
const _hoisted_4 = { class: "col-auto py-0" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mb-6" }
const _hoisted_7 = { class: "mb-6" }
const _hoisted_8 = { class: "row mx-n1" }
const _hoisted_9 = { class: "col py-0 px-1" }
const _hoisted_10 = { class: "mb-6" }
const _hoisted_11 = { class: "col py-0 px-1" }
const _hoisted_12 = { class: "mb-6" }
const _hoisted_13 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_dialog = _resolveComponent("app-dialog")!

  return (_openBlock(), _createBlock(_component_app_dialog, {
    open: _ctx.open,
    width: 450,
    onDialogClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("button.add")) + " " + _toDisplayString(_ctx.t("dictionary.link")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            icon: "",
            onClick: _ctx.closeModal,
            class: "ma-n3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 16
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_text_field, {
            name: "name",
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            label: _ctx.t("dictionary.name"),
            placeholder: _ctx.t("form.placeholder.addName"),
            outlined: "",
            errorMessage: _ctx.nameError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_app_text_field, {
            name: "lr_name",
            modelValue: _ctx.lr_name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lr_name) = $event)),
            label: _ctx.t("dictionary.lrName"),
            placeholder: _ctx.t("form.placeholder.addLrName"),
            outlined: "",
            errorMessage: _ctx.lrNameError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_app_text_field, {
            name: "rl_name",
            modelValue: _ctx.rl_name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rl_name) = $event)),
            label: _ctx.t("dictionary.rlName"),
            placeholder: _ctx.t("form.placeholder.addRlName"),
            outlined: "",
            errorMessage: _ctx.rlNameError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_app_text_field, {
                name: "priority",
                modelValue: _ctx.priority,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.priority) = $event)),
                label: _ctx.t("dictionary.priority"),
                placeholder: _ctx.t("form.placeholder.addPriority"),
                outlined: "",
                errorMessage: _ctx.priorityError
              }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_app_text_field, {
                name: "uniq_for_res",
                modelValue: _ctx.uniq_for_res,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.uniq_for_res) = $event)),
                label: _ctx.t("dictionary.uniq_for_res"),
                placeholder: _ctx.t("form.placeholder.addUniqForRes"),
                outlined: "",
                errorMessage: _ctx.uniqForResError
              }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_app_checkbox, {
            id: "mandatory",
            label: _ctx.t("dictionary.mandatory"),
            selected: _ctx.mandatory,
            "onUpdate:selected": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.mandatory) = $event))
          }, null, 8, ["label", "selected"])
        ]),
        _createVNode(_component_app_button, {
          block: "",
          large: "",
          color: "bg-blue text-white",
          onClick: _ctx.add,
          loading: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("button.add")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["open", "onDialogClosed"]))
}