
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {TypeLinks} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: lr_name, errorMessage: lrNameError } = useField<string>('lr_name', 'required');
		const { value: rl_name, errorMessage: rlNameError } = useField<string>('rl_name', 'required');
		const { value: priority, errorMessage: priorityError } = useField<string>('priority', 'required|decimal');
		const { value: uniq_for_res, errorMessage: uniqForResError } = useField<string>('uniq_for_res', 'required|decimal');

		const mandatory = ref(false);

		const isLoading = ref(false);
		const service = injectOrThrow<TypeLinks.TypeLinkService>('TypeLinks.TypeLinkService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.add({
					name: values.name,
					id_enterprise: store.state.globals.enterpriseId,
					lr_name: values.lr_name,
					rl_name: values.rl_name,
					mandatory: mandatory.value,
					priority: values.priority,
					uniq_for_res: values.uniq_for_res
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.link")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			name, nameError,
			lr_name, lrNameError,
			rl_name, rlNameError,
			priority, priorityError,
			uniq_for_res, uniqForResError,
			mandatory,
			isLoading, add, closeModal
		}
	}
})
